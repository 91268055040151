const states = {
  CANCELED: "ANULADO",
  VERIFICATION: "EN VERIFICACION",
  MANAGEMENT: "EN GESTION",
  REVISION: "EN REVISION",
};

Object.freeze(states);

export default states;
