import downloadFile from "../utils/downloadFile";
import baseService from "./baseService";

const resource = "/rest/v1";

export function eventTypesList() {
  return baseService.get(`${resource}/human_rights/event-type/list`);
}

export function listEventTypeRequires() {
  return baseService.get(`${resource}/human_rights/event_type_requires/list`);
}

export function eventTypesListDis() {
  return baseService.get(`${resource}/human_rights/event-type/dis/list`);
}

export function eventSubTypeEvents() {
  return baseService.get(`${resource}/human_rights/sub-event-type/list`);
}

export function storeSubTypeEvents(eventData) {
  return baseService.post(
    `${resource}/human_rights/sub-event-type/store`,
    eventData
  );
}

export function storeEventDisharmony(eventData) {
  return baseService.post(
    `${resource}/human_rights/event-type/store`,
    eventData
  );
}

export function storeEventTypeRequires(eventData) {
  return baseService.post(
    `${resource}/human_rights/event_type_requires/store`,
    eventData
  );
}

export function storeEventTypeRequiresOption(eventData) {
  return baseService.post(
    `${resource}/human_rights/event_type_requires_options/store`,
    eventData
  );
}

export function showEventTypeRequires(id) {
  return baseService.get(
    `${resource}/human_rights/event_type_requires/show/${id}`
  );
}

export function showEventTypeRequiresOption(id) {
  return baseService.get(
    `${resource}/human_rights/event_type_requires_options/show/${id}`
  );
}

export function updateEventTypeRequires(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/event_type_requires/update/${id}`,
    eventData
  );
}

export function updateEventTypeRequiresOption(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/event_type_requires_options/update/${id}`,
    eventData
  );
}

export function showTypeEvent(id) {
  return baseService.get(`${resource}/human_rights/typeevent/show/${id}`);
}

export function showRequestClass(id) {
  return baseService.get(`${resource}/human_rights/request-class/show/${id}`);
}

export function showTypeSubEvent(id) {
  return baseService.get(`${resource}/human_rights/sub-event-type/show/${id}`);
}

export function showStates(id) {
  return baseService.get(`${resource}/human_rights/states/show/${id}`);
}

export function updateTypeSubEvent(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/sub-event-type/update/${id}`,
    eventData
  );
}

export function updateStatus(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/states/update/${id}`,
    eventData
  );
}

export function updateTypeEvent(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/typeevent/update/${id}`,
    eventData
  );
}

export function updateRequestClass(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/request-class/update/${id}`,
    eventData
  );
}

export function storeDisharmony(eventData) {
  return baseService.post(
    `${resource}/human_rights/disharmony-type/store`,
    eventData
  );
}

export function listEvents(params = {}) {
  return baseService.get(`${resource}/human_rights/event`, {
    params: params,
  });
}

export function listEventTypeRequiresOption() {
  return baseService.get(
    `${resource}/human_rights/event_type_requires_options/list`
  );
}

export function list_() {
  return baseService.get(`${resource}/human_rights/event`);
}

export function showEvent(id) {
  return baseService.get(`${resource}/human_rights/event/show/${id}`);
}

export function showDisharmony(id) {
  return baseService.get(`${resource}/human_rights/disharmony/show/${id}`);
}

export function updateDisharmony(id, eventData) {
  return baseService.put(
    `${resource}/human_rights/disharmony/update/${id}`,
    eventData
  );
}

export function storeEvent(eventData) {
  return baseService.post(`${resource}/human_rights/event/store`, eventData);
}

export function storeEventEvidence(files) {
  return baseService.post(
    `${resource}/human_rights/event/evidence/store`,
    files,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

//estados

export function listStates() {
  return baseService.get(`${resource}/human_rights/state/list`);
}

export function listTypeEvents() {
  return baseService.get(`${resource}/human_rights/event-type/dis/list`);
}

export function storeStatus(eventData) {
  return baseService.post(`${resource}/human_rights/states/store`, eventData);
}

export function storeEventStateTracking(tracking) {
  return baseService.post(
    `${resource}/human_rights/event/tracking/store`,
    tracking
  );
}

export function listRequestClases() {
  return baseService.get(`${resource}/human_rights/request-classes`);
}

export function storeRequestClass(eventData) {
  return baseService.post(
    `${resource}/human_rights/request-class/store`,
    eventData
  );
}

export function listEntities() {
  return baseService.get(`${resource}/human_rights/entity`);
}

export function storeEventEntityTracking(tracking) {
  return baseService.post(
    `${resource}/human_rights/event/entity-tracking/store`,
    tracking
  );
}

export function storeEventEntityTrackingResponse(response) {
  let formData = new FormData();

  formData.append("event_entity_tracking_id", response.eventEntityTrackingId);
  formData.append("date", response.date);
  formData.append("response_type", response.response_type);
  formData.append("description", response.description);
  formData.append("user_id", response.user_id);
  formData.append("entity_id", response.entity_id);

  response.evidences.forEach((file) => {
    formData.append("files", file.file);
  });

  return baseService.post(
    `${resource}/human_rights/event/entity-tracking/response/store`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function downloadEvidenceFile(evidenceId, name) {
  const response = await baseService.get(
    `${resource}/human_rights/event/download-evidence/${evidenceId}`,
    {
      responseType: "blob",
    }
  );

  downloadFile(response.data, "", name);
}

export function getForm(code) {
  return baseService.get(`${resource}/human_rights/get-form/${code}`);
}

export function storeFormResponse(formResponse) {
  return baseService.post(
    `${resource}/human_rights/store-form-response`,
    formResponse
  );
}

export function updateFormResponse(id, formResponse) {
  return baseService.put(
    `${resource}/human_rights/update-form-response/${id}`,
    formResponse
  );
}

export function updateEvent(data, id) {
  return baseService.put(`${resource}/human_rights/event/update/${id}`, data);
}

export function getActionRoutes() {
  return baseService.get(`${resource}/human_rights/get-action-routes`);
}

export function getAffectedTracking(id) {
  return baseService.get(
    `${resource}/human_rights/get-affected-tracking/${id}`
  );
}

export function storeAffectedTracking(data) {
  return baseService.post(
    `${resource}/human_rights/store-affected-tracking`,
    data
  );
}

// get dashboard data
export function getDashboardData(isFemale = 0) {
    return baseService.get(`${resource}/human_rights/dashboard-stats`, {
        params: {
          isFemale : isFemale
        }
    });
}

// store mail template
export function storeMailTemplate(data) {
    return baseService.post(`${resource}/human_rights/store-mail-template`, data);
}

// update mail template
export function updateMailTemplate(id, data) {
    return baseService.put(`${resource}/human_rights/update-mail-template/${id}`, data);
}

// list mail template
export function listMailTemplate() {
    return baseService.get(`${resource}/human_rights/list-mail-template`);
}

// send mail
export function sendMail(event_request_id) {
    return baseService.get(`${resource}/human_rights/send_mail/${event_request_id}`);
}

