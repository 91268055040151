import states from "../enums/eventStates";

function getEventStateColor(state) {
  return {
    [states.CANCELED]: "red",
    [states.VERIFICATION]: "orange",
    [states.MANAGEMENT]: "secondary",
    [states.REVISION]: "blue",
  }[state];
}

export default getEventStateColor;
